import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				МайстерКар
			</title>
			<meta name={"description"} content={"Винятковий догляд за автомобілем"} />
			<meta property={"og:title"} content={"МайстерКар"} />
			<meta property={"og:description"} content={"Винятковий догляд за автомобілем"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1610092708835-af669294f3f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1610092708835-af669294f3f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1610092708835-af669294f3f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1610092708835-af669294f3f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1610092708835-af669294f3f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1610092708835-af669294f3f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1610092708835-af669294f3f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Marque />
		<Components.Header />
		<Section padding="80px 0 80px 0" background="--color-dark" color="--lightD2" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="100px"
				width="40%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--light">
					<Em>
						Завітайте до нас!
					</Em>
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--lightD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					Замовте послугу сьогодні, і ми покажемо вам, чому МайстерКар є лідером у сфері догляду за автомобілями. Наше прагнення до якості та задоволеності клієнтів гарантує неперевершене обслуговування щоразу. У МайстерКар ми не просто ремонтуємо автомобілі, ми вдосконалюємо їх.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+9877654321223"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							Mariins'ka St, 6, Odesa,{" "}
							<br />
							Odesa Oblast, 65000
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="0487280165"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							0487280165
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdMailOutline}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="info@soluxionpro.com"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							info@soluxionpro.com
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							FAQ{" "}
							<br />
							<Em font-weight="normal">
								Поширені запитання
							</Em>
						</Text>
						<Text as="p" font="--base" margin="0" color="--dark">
							У МайстерКар ми прагнемо надавати найкращі послуги. Нижче ви знайдете відповіді на деякі з найпоширеніших запитань, які ми отримуємо від наших клієнтів.
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Які послуги ви пропонуєте в МайстерКар?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Ми пропонуємо повний спектр послуг по догляду за автомобілем, включаючи планове технічне обслуговування, ремонт механіки, електронну діагностику, кузовне обслуговування, заміну масла, перевірку та заміну шин та багато іншого.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Як записатися на прийом?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Ви можете записатися на прийом, зателефонувавши нам за номером 0487280165, надіславши електронний лист на адресу info@soluxionpro.com або завітавши до нас за адресою Mariins'ka St, 6, Odesa.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Скільки часу займає заміна масла?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Зазвичай заміна масла займає приблизно 30-45 хвилин. Однак час може відрізнятися залежно від типу транспортного засобу та конкретних умов.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Чи пропонуєте ви послуги екстреного ремонту?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Так, ми пропонуємо послуги екстреного ремонту, щоб ваш автомобіль повернувся в дорогу якомога швидше. Негайно зв'яжіться з нами, якщо вам потрібна термінова допомога.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Чи надаємо ми автомобіль на заміну, поки мій ремонтується?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Так, ми пропонуємо замінні автомобілі для клієнтів, чий автомобіль потребує капітального ремонту. Уточнюйте наявність у нашої команди під час бронювання.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Які види оплати ви приймаєте?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Ми приймаємо готівку, кредитні/дебетові картки та банківські перекази. Ви можете обговорити варіанти оплати з нашою командою під час обслуговування.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Як отримати розцінку на ремонт?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Щоб отримати ціну, ви можете привезти свій автомобіль до нас на діагностику або зв’язатися з нами з детальним описом проблеми. Ми надамо вам детальну пропозицію відповідно до конкретних потреб вашого автомобіля.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Ви використовуєте оригінальні запчастини?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Так, ми використовуємо оригінальні запчастини для забезпечення максимальної якості та сумісності з вашим автомобілем. Якщо ви бажаєте, ми також можемо обговорити використання високоякісних запасних частин.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Як часто я повинен перевіряти свій автомобіль?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Ми рекомендуємо перевіряти свій автомобіль принаймні раз на рік або кожні 10 000 кілометрів, залежно від того, що відбудеться раніше. Це допоможе підтримувати ваш автомобіль у відмінному стані та запобігти майбутнім проблемам.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Чи надаєте ви гарантії на свої послуги?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Так, ми надаємо гарантії на наші послуги, щоб забезпечити вам повний спокій. Тривалість та умови гарантії залежать від типу виконаної послуги. Зв’яжіться з нами для отримання додаткової інформації щодо конкретних гарантій.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text3">
				Mariins'ka St, 6, Odesa,{" "}
				<br />
				Odesa Oblast, 65000
			</Override>
			<Override slot="link3">
				0487280165
			</Override>
			<Override slot="link4">
				info@soluxionpro.com
			</Override>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});